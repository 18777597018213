<template>
  <div class="analyse_month">
    <div class="width">
      
      <div class="col_duo">
        
        <div class="graph shadowSmall">
          <div class="title_color total" :class="type">Evolution des secteurs </div>
          <v-chart :options="graph_secteurs" autoresize v-if="load" />
        </div>
        <div class="graph shadowSmall">
          <div class="title_color jeux" :class="type">Evolution des JT </div>
          <v-chart :options="graph_jt" autoresize v-if="load" />
        </div>
      </div>
      
      
      
      <div class="tableau no_margin" :class="{ load: load === false } ">
        <div class="ligne head" style="grid-template-columns: 8% 6% 12% 8% 12% 8% 12% 8% 10% 8% 8%">
          <div class="e"></div>
          <div class="e">Entrées</div>
          <div class="e avant_red">Total</div>
          <div class="e">Hors jeu</div>
          <div class="e avant_blue">Machines à sous</div>
          <div class="e">MAS B.P</div>
          <div class="e avant_turq">Roulettes électroniques</div>
          <div class="e">RAE B.P</div>
          <div class="e avant_green">Jeux traditionnels</div>
          <div class="e">Contrepartie</div>
          <div class="e">Poker</div>
        </div>
        
        
        
        
        <div class="ligne zoom_color white2" style="grid-template-columns: 8% 6% 12% 8% 12% 8% 12% 8% 10% 8% 8%">
          <div class="e borderRight left">Prévisionnel</div>
          <div class="e"><el-progress type="circle" :percentage="calc_col('pbj_rae') > 0 ? (calc_col('enter_casino') * 100) / previsionnel.enter : 0" :width="18" :stroke-width="5" :text-inside="true" :color="colors" stroke-linecap="butt"></el-progress>{{ previsionnel.enter | formatNumber }}</div>
          <div class="e avant_red"></div>
          <div class="e euro"><el-progress type="circle" :percentage="calc_col('pbj_rae') > 0 ? (calc_col('total_hj') * 100) / previsionnel.hj : 0" :width="28" :stroke-width="6" :text-inside="true" :color="colors" stroke-linecap="butt"></el-progress>{{ previsionnel.hj | formatNumber }}</div>
          <div class="e avant_blue euro"><el-progress type="circle" :percentage="calc_col('pbj_rae') > 0 ? (calc_col('pbj_mas') * 100) / previsionnel.mas : 0" :width="28" :stroke-width="6" :text-inside="true" :color="colors" stroke-linecap="butt"></el-progress>{{ previsionnel.mas | formatNumber }}</div>
          <div class="e"></div>
          <div class="e avant_turq euro"><el-progress type="circle" :percentage="calc_col('pbj_rae') > 0 ? (calc_col('pbj_rae') * 100) / previsionnel.rae : 0" :width="28" :stroke-width="6" :text-inside="true" :color="colors" stroke-linecap="butt"></el-progress>{{ previsionnel.rae | formatNumber }}</div>
          <div class="e"></div>
          <div class="e avant_green euro"><el-progress type="circle" :percentage="total_jt > 0 ? (total_jt * 100) / previsionnel.jt : 0" :width="28" :stroke-width="6" :text-inside="true" :color="colors" stroke-linecap="butt"></el-progress>{{ previsionnel.jt | formatNumber }}</div>
          <div class="e borderRight"></div>
          <div class="e"></div>
        </div>
        
        <div class="ligne" style="grid-template-columns: 8% 6% 12% 8% 12% 8% 12% 8% 10% 8% 8%;">
          <div class="e borderRight no_border_bottom left">Manque</div>
          <div class="e"><template v-if="previsionnel.enter">{{ previsionnel.enter - calc_col('enter_casino') | formatNumber }}</template></div>
          <div class="e avant_red"></div>
          <div class="e">
            <template v-if="previsionnel.hj && (previsionnel.hj - calc_col('total_hj')) / dim > 0"><span class="euro">{{ previsionnel.hj - calc_col('total_hj') | formatNumber }}</span></template>
          </div>
          <div class="e avant_blue">
            <template v-if="previsionnel.mas && (previsionnel.mas - calc_col('pbj_mas')) / dim > 0"><span class="euro">{{ previsionnel.mas - calc_col('pbj_mas') | formatNumber }}</span></template>
          </div>
          <div class="e"></div>
          <div class="e avant_turq">
            <template v-if="previsionnel.rae && (previsionnel.rae - calc_col('pbj_rae')) / dim > 0"><span class="euro">{{ previsionnel.rae - calc_col('pbj_rae') | formatNumber }}</span></template>
          </div>
          <div class="e"></div>
          <div class="e avant_green">
            <template v-if="previsionnel.jt && (previsionnel.jt - total_jt) / dim > 0"><span class="euro">{{ previsionnel.jt - total_jt | formatNumber }}</span></template>
          </div>
          <div class="e"></div>
          <div class="e"></div>
        </div>
        
        <div class="ligne no_border" style="grid-template-columns: 8% 6% 12% 8% 12% 8% 12% 8% 10% 8% 8%">
          <div class="e borderRight left">Moy/J à faire</div>
          <div class="e"><template v-if="previsionnel.enter">~{{ (previsionnel.enter - calc_col('enter_casino')) / dim | formatNumber }}</template></div>
          <div class="e avant_red"></div>
          <div class="e">
            <template v-if="previsionnel.hj && (previsionnel.hj - calc_col('total_hj')) / dim > 0"><span class="euro">~{{ (previsionnel.hj - calc_col('total_hj')) / dim | formatNumber }}</span></template>
          </div>
          <div class="e avant_blue">
            <template v-if="previsionnel.mas && (previsionnel.mas - calc_col('pbj_mas')) / dim > 0"><span class="euro">~{{ (previsionnel.mas - calc_col('pbj_mas')) / dim | formatNumber }}</span></template>
          </div>
          <div class="e"></div>
          <div class="e avant_turq">
            <template v-if="previsionnel.rae && (previsionnel.rae - calc_col('pbj_rae')) / dim > 0"><span class="euro">~{{ (previsionnel.rae - calc_col('pbj_rae')) / dim | formatNumber }}</span></template>
          </div>
          <div class="e"></div>
          <div class="e avant_green">
            <template v-if="previsionnel.jt && (previsionnel.jt - total_jt) / dim > 0"><span class="euro">~{{ (previsionnel.jt - total_jt) / dim | formatNumber }}</span></template>
          </div>
          <div class="e"></div>
          <div class="e"></div>
        </div>
        
        
        
        
        
        <div class="ligne zoom_color white2" style="grid-template-columns: 8% 6% 12% 8% 12% 8% 12% 8% 10% 8% 8%">
          <div class="e borderRight left">Total</div>
          <div class="e">{{ calc_col('enter_casino') | formatNumber }}</div>
          <div class="e avant_red euro" :class="color(total_total)">{{ total_total | formatNumberdecimal }}</div>
          <div class="e euro">{{ calc_col('total_hj') | formatNumberdecimal }}</div>
          <div class="e avant_blue euro" :class="color(calc_col('pbj_mas'))">{{ calc_col('pbj_mas') | formatNumberdecimal }}</div>
          <div class="e euro">{{ calc_col('bp_mas') | formatNumberdecimal }}</div>
          <div class="e avant_turq euro" :class="color(calc_col('pbj_rae'))">{{ calc_col('pbj_rae') | formatNumberdecimal }}</div>
          <div class="e euro">{{ calc_col('bp_rae') | formatNumberdecimal }}</div>
          <div class="e avant_green euro" :class="color(total_jt)">{{ total_jt | formatNumberdecimalsimple }}</div>
          <div class="e borderRight euro" :class="color(calc_col('total_contrepartie'))">{{ calc_col('total_contrepartie') | formatNumberdecimalsimple }}</div>
          <div class="e euro" :class="color(calc_col('total_poker'))">{{ calc_col('total_poker') | formatNumberdecimalsimple }}</div>
        </div>
        
        <div class="ligne background2 small border" style="grid-template-columns: 8% 6% 12% 8% 12% 8% 12% 8% 10% 8% 8%" v-for="(n, k) in month_tab" :key="k">
          <router-link tag="div" :to="{ name: 'AnalysesDay', params: { at: $dayjs(n.at).format('DD-MM-YYYY') } }" class="e borderRight background1 left no_border_bottom cursor" style="font-weight: 400">{{ $dayjs(n.at).format('ddd DD') }}</router-link>
          <div class="e"><number :value="n.enter_casino" round="formatNumber" edit="day_enter" :edit_key="n.at" @refresh="get_data"></number></div>
          <div class="e avant_red euro" :class="color(n.total_contrepartie + n.total_poker + n.pbj_mas + n.pbj_rae + n.total_hj)">{{ n.total_contrepartie + n.total_poker + n.pbj_mas + n.pbj_rae + n.total_hj | formatNumberdecimal }}</div>
          <div class="e euro not_important">{{ n.total_hj | formatNumberdecimal }}</div>
          <div class="e select_indicator avant_blue"><number :value="n.pbj_mas" round="formatNumberdecimal" :euro="true" :color="true" edit="day_mas_pbj" :edit_key="n.at" @refresh="get_data"></number></div>
          <div class="e not_important euro">{{ n.bp_mas | formatNumberdecimal }}</div>
          <div class="e avant_turq"><number :value="n.pbj_rae" round="formatNumberdecimal" :euro="true" :color="true" edit="day_rae_pbj" :edit_key="n.at" @refresh="get_data"></number></div>
          <div class="e not_important euro">{{ n.bp_rae | formatNumberdecimal }}</div>
          <div class="e euro avant_green" :class="color(n.total_contrepartie + n.total_poker)">{{ n.total_contrepartie + n.total_poker | formatNumberdecimalsimple }}</div>
          <div class="e not_important euro" :class="color(n.total_contrepartie)">{{ n.total_contrepartie | formatNumberdecimalsimple }}</div>
          <div class="e not_important euro" :class="color(n.total_poker)">{{ n.total_poker | formatNumberdecimalsimple }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import _ from 'lodash'
import number from "@/components/number";
export default {
  name: "analyse_month",
  data() {
    return {
      month_tab: [],
      
      load: false,
      
      month: null,
      dim: 0,


      graph_x: [],
      graph_secteurs: {},
      graph_jt: {},
      
      previsionnel: {},
      
      total_total: 0,
      total_jt: 0,
      
      colors: [
        {color: '#f56c6c', percentage: 50},
        {color: '#e6a23c', percentage: 80},
        {color: '#5cb87a', percentage: 100}
      ]
    }
  },
  components: { number },
  methods: {
    color(n) {
      if(Math.sign(n) === -1) return 'red'
      else if(n > 0) return 'green'
    },
    calc_col(key) {
      let r = 0
      for(let n in this.month_tab) {
        if(this.month_tab[n][key])
         r += parseFloat(this.month_tab[n][key])
      }
      return r
    },
    get_data() {
      this.$httpStatum.get('/month/' + this.month).then((response) => {
        console.log(response.data)
        this.month_tab = response.data

        this.total_total = this.calc_col('total_contrepartie') + this.calc_col('total_poker') + this.calc_col('pbj_mas') + this.calc_col('pbj_rae') + this.calc_col('total_hj')
        this.total_jt = this.calc_col('total_contrepartie') + this.calc_col('total_poker')

        this.dim = dayjs('01-' + this.month).daysInMonth() - this.month_tab.length


        this.graph_x = []
        this.graph_secteurs = {}
        this.graph_jt = {}
        
        for(let i = 1; i < dayjs('01-' + this.month).daysInMonth(); i++)
          this.graph_x.push(i)

        let data_graph_o = _.orderBy(this.month_tab, 'at', 'asc')
        
        let graph_data_secteurs = [
          {name: 'Total', type: 'line', data: [], smooth: 0.3, symbolSize: 4, showSymbol: false, itemStyle: { color: '#555', }, lineStyle: { width: 0 },areaStyle: {opacity: .1},},
          {name: 'MAS', type: 'line', data: [], smooth: 0.3, symbolSize: 4, showSymbol: false, itemStyle: { color: '#2263f4', }, lineStyle: { width: 3, shadowColor: 'rgba(0,0,0,0.1)', shadowBlur: 10, shadowOffsetY: 10 },areaStyle: {opacity: .03},},
          {name: 'RAE', type: 'line', data: [], smooth: 0.3, symbolSize: 4, showSymbol: false, itemStyle: { color: '#19b8d2', }, lineStyle: { width: 3, shadowColor: 'rgba(0,0,0,0.1)', shadowBlur: 10, shadowOffsetY: 10 },areaStyle: {opacity: .03},},
          {name: 'JT', type: 'line', data: [], smooth: 0.3, symbolSize: 4, showSymbol: false, itemStyle: { color: '#75b80d', }, lineStyle: { width: 3, shadowColor: 'rgba(0,0,0,0.1)', shadowBlur: 10, shadowOffsetY: 10 },areaStyle: {opacity: .03},},
          {name: 'Hors Jeu', type: 'line', data: [], smooth: 0.3, symbolSize: 4, showSymbol: false, itemStyle: { color: '#f2c03f', }, lineStyle: { width: 3, shadowColor: 'rgba(0,0,0,0.1)', shadowBlur: 10, shadowOffsetY: 10 },areaStyle: {opacity: .03},}
        ]
        
        let label = []
        for(let n in graph_data_secteurs)
          label.push(graph_data_secteurs[n].name)

        
        for(let n in data_graph_o) {
          graph_data_secteurs[0].data.push(data_graph_o[n].pbj_mas + data_graph_o[n].pbj_rae + data_graph_o[n].total_contrepartie + data_graph_o[n].total_poker + data_graph_o[n].total_hj)
          graph_data_secteurs[1].data.push(data_graph_o[n].pbj_mas)
          graph_data_secteurs[2].data.push(data_graph_o[n].pbj_rae)
          graph_data_secteurs[3].data.push(data_graph_o[n].total_contrepartie + data_graph_o[n].total_poker)
          graph_data_secteurs[4].data.push(data_graph_o[n].total_hj)
        }

        this.gener_graph('graph_secteurs', graph_data_secteurs, label)





        let graph_data_jt = [
          {name: 'Total', type: 'line', data: [], smooth: 0.3, symbolSize: 4, showSymbol: false, itemStyle: { color: '#555', }, lineStyle: { width: 0 },areaStyle: {opacity: .1},},
          {name: 'Contrepartie', type: 'line', data: [], smooth: 0.3, symbolSize: 4, showSymbol: false, itemStyle: { color: '#2263f4', }, lineStyle: { width: 3, shadowColor: 'rgba(0,0,0,0.1)', shadowBlur: 10, shadowOffsetY: 10 },areaStyle: {opacity: .03},},
          {name: 'Poker', type: 'line', data: [], smooth: 0.3, symbolSize: 4, showSymbol: false, itemStyle: { color: '#19b8d2', }, lineStyle: { width: 3, shadowColor: 'rgba(0,0,0,0.1)', shadowBlur: 10, shadowOffsetY: 10 },areaStyle: {opacity: .03},},
        ]
        label = []
        for(let n in graph_data_jt)
          label.push(graph_data_jt[n].name)

        for(let n in data_graph_o) {
          graph_data_jt[0].data.push(data_graph_o[n].total_contrepartie + data_graph_o[n].total_poker)
          graph_data_jt[1].data.push(data_graph_o[n].total_contrepartie)
          graph_data_jt[2].data.push(data_graph_o[n].total_poker)
        }

        this.gener_graph('graph_jt', graph_data_jt, label)


        this.load = true
      })
      this.$httpStatum.get('/monthprevisionnel/' + this.month).then((response) => {
        console.log(response.data)
        this.previsionnel = response.data
      })
    },
    
    gener_graph(title, data, label = []) {
      this[title] = {
        legend: { left: '20px', data: label, textStyle: { color: '#7b7b7f', fontFamily: 'Product Sans', fontWeight: '700' },inactiveColor: '#ccc' },
        grid: { left: '20px', right: '30px', top: '50px', bottom: '24px', height: 'auto', containLabel: true },
        xAxis: { type: 'category', boundaryGap: false, data: this.graph_x, axisLine: { lineStyle: { color: '#7b7b7f' } }, show: true },
        yAxis: { type: 'value', axisLabel: { formatter: '{value}€' }, axisPointer: { snap: true }, axisLine: { lineStyle: { width: 0, color: '#7b7b7f' }, },
          splitLine: { lineStyle: { color: '#e6e5eb'  } }
        },
        series: data,
        tooltip: { trigger: 'axis', backgroundColor: '#f0f2f5', textStyle: { color: '#7b7b7f' }, axisPointer: { type: 'cross' } }
      }
    }
    
  },
  created() {

    if(this.$route.params.month)
      this.month = this.$route.params.month
    else 
      this.month = dayjs().format('MM-YYYY')
    

    this.get_data()    
  }
}
</script>

<style scoped>


  .analyse_month .tableau{ margin: 0px 0px 0 0px; margin: 0 16px; background: #fff }
  .ligne.head { border-bottom: 0; }
  
  .col_duo { display: flex; align-items: center; justify-content: space-between; margin: 16px 16px 32px}
  .col_duo .graph:nth-child(1) { margin-right: 16px; }
  /*.col_duo .graph:nth-child(2) { margin-left: 8px; }*/
  .col_duo .graph { width: 50%; background: #fff; min-height: 455px; }
  .col_duo .graph .echarts { width: 100%; }
  
  /*.tableau { width: 100%; display: table }*/
  /*.ligne { display: table-row }*/
  /*.ligne .e { display: table-cell; text-align: center }*/
  /**/
  /*.tableau .euro:after { top: 0 }*/

</style>